import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Foot, Slider,Float } from '../components/main'
import { CSVLink, CSVDownload } from "react-csv";
import { Form, Row, Col, Button, Div } from 'react-bootstrap'
import '../style/gold4.css'
import cincin from '../assets/img/gold4/cincin.svg'
import groom from '../assets/img/groom.png'
import music from '../assets/music/music3.aac'
import logoig from '../assets/img/gold4/logoig.svg'
import logoig1 from '../assets/img/logoig1.svg'
import bunga from '../assets/img/bunga.svg'
import divider from '../assets/img/divider1.svg'
import AOS from 'aos';
import "aos/dist/aos.css";
import { Helm } from '../components/header'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'


export default class Halo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide:true
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });



  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/calendar/r/eventedit?
text=Event%20Title
&details=Pernikahan%20Willy%20dan%20Jessica
&dates=20201010T100000/20201010T113000
&ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
    `, '_blank')
  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();
    this.setState({hide:false})
    setTimeout(()=>{
      var elmnt = document.getElementById('open');
      elmnt.scrollIntoView();
    },1000)
  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
let {hide}=this.state
    return (
      <>
      <Helm
          title='Undanganku - Gold 4'
          desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
          logo={logo}
        />
        <div style={{ width: '100vw', overflow: 'hidden' }} id='gold4'>

        {
            this.useQuery().get('x')=="x"?(<Float/>):false
          }
          <div className="jumbotrongold4 jumbotron-fluid mb-0" id='aftermodal'>
            <div className="container">
              <Item><p className='poppins'>The Wedding of</p>
              </Item>
              <div style={{
                fontSize: '3rem',
                fontFamily: 'Sacramento, cursive',
                lineHeight: 1
              }}
              >
                <Item>

                  Adam
            </Item>
                <Item>
                  &
            </Item>
                <Item>
                  Juwita
            </Item>
              </div>
              <div style={{
                marginTop: '100px'
              }}>
                <Item>
                  <div className='tombol btn'
                    onClick={() => {
                      this.play()
                    }}
                    style={{
                    }}>Open Invitation</div>
                </Item>
              </div>

            </div>

          </div>

          <div className={hide?'d-none':'d-block'} id='open'>
          <Header />
          <Container id='top'
            fluid
            style={{
              paddingTop: '5vw',
              paddingBottom: '5vw',
              fontStyle: 'italic',
              fontWeight: '600',
              color: '#7f8aa9ff',
            }}
          >
            <Item>
              <p className='text-center col-md-8 quotes' data-aos="fade-left"
                data-aos-duration="2000">
                “And of His signs is that He created for you from yourselves mates that youmay
                find tranquility in them; and He placed between you affection and mercy.Indeed
            in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
            </p>
            </Item>
          </Container>

          <Container
            fluid
            style={{
              paddingTop: '5vw',
              paddingBottom: '5vw',
              backgroundColor: '#7f8aa9ff',
              color: '#fff '
            }}
            className='text-center' id='ucapan'
          >
            <Item >
              <img src={cincin}
                className='text-center cincin m-4' data-aos="fade-left"
                data-aos-duration="2000" />
            </Item>
            <div >
              <Item>
                <img src={groom}
                  style={{
                    borderRadius: '1rem',
                    width: '80vw'
                  }
                  } className='text-center' data-aos="fade-left"
                  data-aos-duration="2000"
                  className='fotopasangan' />
              </Item>
              <Mempelai>Lorem Ipsum</Mempelai>
              <div className='f-larger'>



                <Item>Putra dari:</Item>
                <Item><p className='text-center' data-aos="fade-left"
                  data-aos-duration="2000" style={{ fontStyle: 'italic' }}>Bapak Lorem Ipum dan Ibu Dolor Sit</p></Item>
                <Item><img className='text-center' data-aos="fade-left"
                  data-aos-duration="2000" src={logoig} style={{ width: '50px', marginTop: '20px' }} /></Item>
              </div>
            </div>
            <div>
              <Mempelai>&</Mempelai></div>
            <div data-aos="flip-left"
              data-aos-duration="2000">
              <Item>
                <img src={groom}
                  style={{
                    borderRadius: '1rem',
                    width: '80vw'
                  }
                  }
                  className='fotopasangan' />
              </Item>
              <Mempelai>Lorem Ipsum</Mempelai>
              <div className='f-larger'>
              <Item>Putra dari:</Item>
              <Item><p className='text-center' data-aos="fade-left"
                data-aos-duration="2000" style={{ fontStyle: 'italic' }}>Bapak Lorem Ipum dan Ibu Dolor Sit</p></Item>
              <Item><img className='text-center' data-aos="fade-left"
                data-aos-duration="2000" src={logoig} style={{ width: '50px', marginTop: '20px' }} /></Item>
            </div>
          </div>
        </Container>



        <Container
          fluid
          style={{
            paddingTop: '5vw',
            paddingBottom: 0,
            color: '#7f8aa9ff',
            maxWidth: '100vw',
            backgroundColor:'#E1E7F6'
          }}
          className='text-center w-100'
        >

          <Item><img className='text-center' data-aos="fade-left"
            data-aos-duration="2000" src={bunga} style={{ width: '150px', marginTop: '20px' }} /></Item>
          <Item><p className='savethedate' style={{ fontSize: '1rem', color: '#6e6e6eff' }} data-aos="fade-left"
            data-aos-duration="2000">
            Save The Date
            </p></Item>
          <div data-aos="fade-left"
            data-aos-duration="2000">


            <Mempelai>
              Resepsi
          </Mempelai>
            <Item data-aos="fade-left"
              data-aos-duration="2000"><p style={{ color: '#6e6e6eff', fontWeight: '600' }}>
                Rabu,19 Oktober 2020
            </p></Item>
            <Item data-aos="fade-left"
              data-aos-duration="2000"><p style={{ color: '#6e6e6eff' }}>
                18:00-21:00<br />
          Gedung Lorem, Surabaya Jawa Timur
            </p></Item>
            <Item ><div className='col-6 col-md-4 p-1' type='button'
              style={{ backgroundColor: '#7f8aa9ff', borderRadius: '10px', color: '#fff' }}>
              View on Google Maps
              </div></Item>
              <Item ><div onClick={()=>{this.googleCalendar()}}className='col-6 col-md-4 p-1 m-3' type='button'
              style={{ backgroundColor: '#7f8aa9ff', borderRadius: '10px', color: '#fff' }}>
              Add to Calendar
              </div></Item>
          </div>
          <Item><p className='quotes col-md-8'
            style={{ color: '#7f8aa9ff', fontStyle: 'italic', fontWeight: '600', marginTop: '50px' }}>
            Tanpa Mengurangi Rasa Hormat, Semua tamu harap menerapkan protocol kesehatan
            dengan menggunakan maskerke dalam ruangan Resepsi dan Menggunakan Hand Sanitizer
              yang sudah disediakan <br /><br />Terimakasih
            </p></Item>
          <Item >
            <iframe className='col-12 col-md-8 p-2 video' style={{ minHeight: '40vh' }} src="https://www.youtube.com/embed/gaKiJE2C8Tk"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
            picture-in-picture"
              allowfullscreen></iframe>
          </Item>
          <div className='mbx-1'>
            <Item data-aos="fade-left"
              data-aos-duration="2000">

              <img src={divider} style={{ minWidth: '100vw', minHeight: '100%' }} />
            </Item>
          </div>
        </Container>

        <Container id='slider'
          fluid
          style={{
            marginTop: '-1px',
            paddingTop: '5vw',
            paddingBottom: 0,
            color: '#7f8aa9ff',
            backgroundColor: '#7f8aa9ff',
          }}
          className='text-center'
        >
          <Mempelai>
            <h1 data-aos="fade-left"
              data-aos-duration="2000">
              Our Happinnes</h1>
          </Mempelai>
          <Slider />
          <div style={{ padding: '20px' }}>
            <Item>
              <div className='col-12 col-md-6 mt-4' data-aos="fade-left"
                data-aos-duration="2000"
                style={{ backgroundColor: '#fff', borderRadius: '1rem' }}>
                <Mempelai>
                  <p className='pt-2 pb-0 send' >
                    Send Your Wishes
                </p>
                </Mempelai>
                <Col xs={12}  className='bg-white rounded poppins pb-4' id='form'>
                        <form className="col-12 w-100 ">
                        <input type='text' className="col-12 w-100 text-center" placeholder="Nama" />
                        <input type='text' className="col-12 w-100 text-center" placeholder="Alamat" />
                        <input type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" />
                        <Item>
                          <input id="option" type="radio" name="field" value="option" defaultChecked/>
                          <label for="option"><span><span></span></span>Hadir</label>
                          <input id="option" type="radio" name="field" value="option" />
                          <label for="option"><span><span></span></span>Tidak Hadir</label>
                        </Item>
                        <Item>
                          <div  className='col-6 button rounded btn p-0'> Kirim </div>
                        </Item>
                      </form>
              
                        </Col>
              </div>
            </Item>
          </div>
        </Container>

        <Foot ig={logoig} dark/>
</div>
      </div>
      </>


    )
  }
}